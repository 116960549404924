import { createSlice } from "@reduxjs/toolkit";

export const initialState = {};

export const displayBlogSlice = createSlice({
  name: "displayBlog",
  initialState,
  reducers: {
    setDisplayBlogsData: (state, action) => {
      state.setDisplayBlogsData = action.payload;
    },
    setDisplayBlogsOptions: (state, action) => {
      state.setDisplayBlogsOptions = action.payload;
    },
  },
});

export const { setDisplayBlogsData, setDisplayBlogsOptions } =
  displayBlogSlice.actions;

export default displayBlogSlice.reducer;
