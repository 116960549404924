import { createSlice } from "@reduxjs/toolkit";

export const initialState = {};

export const displayFaqSlice = createSlice({
  name: "displayFaq",
  initialState,
  reducers: {
    setDisplayFaqsData: (state, action) => {
      state.setDisplayFaqsData = action.payload;
    },
    setDisplayFaqsOptions: (state, action) => {
      state.setDisplayFaqsOptions = action.payload;
    },
  },
});

export const { setDisplayFaqsData, setDisplayFaqsOptions } =
  displayFaqSlice.actions;

export default displayFaqSlice.reducer;
