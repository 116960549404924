import { createSlice } from "@reduxjs/toolkit";

export const initialState = {};

export const displayReviewSlice = createSlice({
  name: "displayReview",
  initialState,
  reducers: {
    setDisplayReviewsData: (state, action) => {
      state.setDisplayReviewsData = action.payload;
    },
    setDisplayReviewsOptions: (state, action) => {
      state.setDisplayReviewsOptions = action.payload;
    },
  },
});

export const { setDisplayReviewsData, setDisplayReviewsOptions } =
  displayReviewSlice.actions;

export default displayReviewSlice.reducer;
