import { createSlice } from "@reduxjs/toolkit";

export const initialState = {};

export const displayTermsSlice = createSlice({
  name: "displayTerms",
  initialState,
  reducers: {
    setDisplayTermsData: (state, action) => {
      state.setDisplayTermsData = action.payload;
    },
    setDisplayTermsOptions: (state, action) => {
      state.setDisplayTermsOptions = action.payload;
    },
  },
});

export const { setDisplayTermsData, setDisplayTermsOptions } =
  displayTermsSlice.actions;

export default displayTermsSlice.reducer;
