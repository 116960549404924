import { combineReducers } from "redux";
import theme from "./theme/themeSlice";
import auth from "./auth";
import base from "./base";
import displayBrands from "./displayBrands";
import displayTerms from "./displayTerms";
import displayPrivacy from "./displayPrivacy";
import displayBlog from "./displayBlogs";
import displayReview from "./displayReviews";
import displayFaq from "./displayFaqs";
import blogs from "./blogs";
import writers from "./writers";
import config from "./config";

const rootReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    theme,
    auth,
    base,
    displayBrands,
    displayBlog,
    displayReview,
    displayFaq,
    blogs,
    writers,
    config,
    displayTerms,
    displayPrivacy,
    ...asyncReducers,
  });
  return combinedReducer(state, action);
};

export default rootReducer;
