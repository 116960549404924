import { createSlice } from "@reduxjs/toolkit";

export const initialState = {};

export const displayPrivacySlice = createSlice({
  name: "displayPrivacy",
  initialState,
  reducers: {
    setDisplayPrivacyData: (state, action) => {
      state.setDisplayPrivacyData = action.payload;
    },
    setDisplayPrivacyOptions: (state, action) => {
      state.setDisplayPrivacyOptions = action.payload;
    },
  },
});

export const { setDisplayPrivacyData, setDisplayPrivacyOptions } =
  displayPrivacySlice.actions;

export default displayPrivacySlice.reducer;
